import React from "react";

const LinkArrow = () => {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.49914 0.970639C9.48292 0.694973 9.2463 0.484647 8.97064 0.500863L4.4784 0.765112C4.20274 0.781327 3.99241 1.01794 4.00863 1.29361C4.02484 1.56928 4.26146 1.7796 4.53713 1.76339L8.53022 1.5285L8.76511 5.5216C8.78133 5.79726 9.01794 6.00759 9.29361 5.99137C9.56928 5.97516 9.7796 5.73854 9.76339 5.46287L9.49914 0.970639ZM1.3737 10.3322L9.3737 1.33218L8.6263 0.667818L0.626295 9.66782L1.3737 10.3322Z"
        fill="black"
      />
    </svg>
  );
};

export default LinkArrow;
